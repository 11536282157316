import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/Layout'




/**
 *
 * Data is read from src/data/activities.json. Add/Remove activities there!
 */
const ActiviteitenPage = ({ data }) => {
  const { edges: activities } = data.activitiesList || []


  return (

    <Layout
      pageTitle="Activiteiten"
      heroTitle="Activiteiten"
      heroSubTitle=""
      heroTagLine=""
      heroImage=""
      metaDescription="Elk seizoen organiseren we verschillende activiteiten. Ontdek ze hier!"
    >


      <div className="container">
        <ul className="skew-column-container mt-5 mb-8">
          {
            activities.map((activity, key) => (



              <li className={`skew-card ${(activity.node.daysDiff >= 0) ? 'past-event' : ''}`} key={key}>

                <div className="skew-card__content">
                  <div className="skew-date">
                    <time dateTime={activity.node.dateAmerican}>
                      {activity.node.fullDate}
                    </time>
                  </div>
                  <h3 className="h5 skew-card__title">
                    {activity.node.title}
                  </h3>
                  <div>
                    <p>
                      {activity.node.audience}
                    </p>
                  </div>
                </div>

              </li>



            ))

          }


        </ul>
      </div>


    </Layout>
  )
}

export default ActiviteitenPage


export const query = graphql`
query {
  activitiesList: allActivitiesJson(sort: {fields: date}) {
    edges {
      node {
        audience
        daysDiff: date(difference: "days")
        fullDate: date(locale: "nl-be", formatString: "D MMMM YYYY")
        dateAmerican: date(formatString: "YYYY-MM-DD")
        isFuture: date(fromNow: false)
        title
      }
    }
  }
}
`
